/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { GlobalStyles, Box, Stack } from '@trustyou/ty-brew/mui';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import Router from './Tabs/Router';
import RouterLegacy from './TabsLegacy/RouterLegacy';
import config from '../../config';
import PageLoading from './PageLoading';
import Sidebar from './Sidebar';
import SidebarSkeleton from './Sidebar/SidebarSkeleton';
import Topbar from './Topbar';
import TopbarSkeleton from './Topbar/TopbarSkeleton';
import { setIsLoading, setLabels, setSession } from '../../redux/slices/app';
import I18nService from '../../services/I18nService';
import CXPStartingModal from '../CXP/CXPStartingModal/Index';
import { cxpEmailCheck } from '../CXP/service';

const Tyra = () => {
  const isLoading = useSelector((state) => state.app.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCXPMigrationModal, setShowCXPMigrationModal] = useState(false);

  //CXP Email Check
  const { mutate: checkCXPEmail } = useMutation(cxpEmailCheck, {
    onSuccess: () => {
      // localStorage.setItem('migrated-to-cxp', JSON.stringify(session.user.id));
      navigate('/cxp/cxp_already_upgraded');
    },
  });

  useEffect(() => {
    // requirejs is global var from commons
    requirejs(
      [
        'jquery',
        'session',
        'users-app', // login-as logic for tyra-5-ui UserSwitcher (service users). TODO: move to UserSwitcher component
        'zendesk_chat', // supportChat zendesk logic
        'portfolio-app',
      ],
      ($, session) => {
        // jQuery UI datepicker localization, by default shows japanese
        $.datepicker.setDefaults($.datepicker.regional[session.properties?.locale] || $.datepicker.regional['']);

        // Sending userID to GTM
        TagManager.dataLayer({
          dataLayer: {
            event: 'logged_user_id',
            userId: session.user.id,
          },
        });

        const i18nService = new I18nService(config.urls.host);
        i18nService
          .getBundle({
            bundle: 'tyra-5',
            lang: session.properties?.locale,
          })
          .then((labels) => {
            // update redux state
            batch(() => {
              dispatch(setLabels(labels));
              dispatch(
                setSession({
                  account: session.account,
                  defaultCluster: session.defaultCluster,
                  selectedCluster: session.selectedCluster,
                  expired_permissions: session.expired_permissions,
                  permissions: session.permissions,
                  products: session.products,
                  properties: session.properties,
                  roles: session.roles,
                  ssoToken: session.ssoToken,
                  trial_permissions: session.trial_permissions,
                  user: session.user,
                })
              );
              dispatch(setIsLoading(false));
              // CXP Email Check
              if (session.roles.includes('to_be_migrated')) {
                checkCXPEmail({ userId: session.user.id, ssoToken: session.ssoToken });
              }
              // CXP Migration Checks
              // JSON.parse(localStorage.getItem('migrated-to-cxp')) === session.user.id
              session.roles.includes('migrated_to_cxp') && navigate('/cxp/cxp_already_upgraded');
              setShowCXPMigrationModal(session.roles.includes('to_be_migrated'));
            });
          });
      }
    );
  }, [dispatch]);

  return (
    <>
      {showCXPMigrationModal && <CXPStartingModal />}
      <GlobalStyles />
      <Box
        sx={{
          display: 'grid',
          height: '100vh',
          gridTemplateColumns: 'auto 1fr',
          gridTemplateRows: 'auto 1fr',
          gap: '0px 0px',
          gridTemplateAreas: `
        "sidebar topbar"
        "sidebar main"`,
        }}
      >
        <Box id='sidebar' sx={{ gridArea: 'sidebar' }}>
          {isLoading ? <SidebarSkeleton /> : <Sidebar />}
        </Box>
        <Box id='navbar' sx={{ gridArea: 'topbar' }}>
          {isLoading ? <TopbarSkeleton /> : <Topbar />}
        </Box>
        <Stack id='main' sx={{ gridArea: 'main', backgroundColor: 'background.default', overflow: 'auto' }}>
          {isLoading ? <PageLoading sx={{ paddingTop: '20px' }} /> :
          <>
            <Stack id='react-main-legacy' sx={{ flexWrap: 'wrap' }}>
              <RouterLegacy />
            </Stack>
            <Stack id='react-main' sx={{ flexGrow: 1 }}>
              <Router />
            </Stack>
          </>
          }
        </Stack>
        <Box id='right-sidr-cont'>
          <Box
            id='side-panel-reports'
            className='trustyou-ui side-panel side-panel-lg l-right'
            sx={{ zIndex: 999999, a: { textDecoration: 'none' } }}
          ></Box>
        </Box>
      </Box>
    </>
  );
};

export default Tyra;
