import React, { useState } from 'react';
import { Stack, Box, Typography, Paper } from '@trustyou/ty-brew/mui';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '../../../config';
import CXPInputFields from './CXPInputFields';
import CXPSuccess from './CXPSuccess';

const SideBarImage = () => (
  <Box
    sx={(_theme) => ({
      display: 'flex',
      backgroundImage: `url(${config.urls.tyAssets}/common/img/logos/cxp-sidebar.png)`,
      backgroundSize: 'cover',
      [_theme.breakpoints.up('md')]: {
        width: '20%',
      },
    })}
  />
);

const CXPSetPassword = () => {
  const session = useSelector((state) => state.app.session);
  const [cxpTransferSuccessful, setCXPTransferSuccessful] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        <Box
          component='img'
          sx={{
            width: '185px',
            height: '20px',
            marginBottom: 5,
          }}
          src={`${config.urls.tyAssets}/common/img/logos/ty_logo_300x32.png`}
        />
        {/* || JSON.parse(localStorage.getItem('migrated-to-cxp')) === session.user.id  */}
        {cxpTransferSuccessful ? (
          <CXPSuccess />
        ) : (
          <>
            <Stack sx={{ alignItems: 'center', marginY: 2 }}>
              <Typography variant='h6' color='text.primary' sx={{ marginBottom: 1 }}>
                {/* <FormattedMessage
                  id='cxp_set_password_title'
                  defaultMessage='Welcome to Customer Experience Platform'
                /> */}
                <FormattedMessage id='cxp_set_password_title_vog' defaultMessage='Welcome to VOG 2.0' />
              </Typography>
              <Typography variant='body1' color='text.secondary'>
                <FormattedMessage
                  id='cxp_set_password_description_1'
                  defaultMessage='To get started, set a password for'
                />{' '}
                <a href='' target='_blank' rel='noopener noreferrer'>
                  {session?.user?.email}
                </a>
              </Typography>
              <Typography variant='body1' color='text.secondary' sx={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id='cxp_set_password_description_2'
                  defaultMessage='Your email address will be your new username'
                />
              </Typography>
            </Stack>
            <CXPInputFields setCXPTransferSuccessful={setCXPTransferSuccessful} />
          </>
        )}
      </Paper>
      <SideBarImage />
    </Box>
  );
};

export default CXPSetPassword;
