import React from 'react';
import { Stack, Typography } from '@trustyou/ty-brew/mui';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Icon from '@trustyou/ty-brew/components/StyledFontAwesomeIcon';
import { faCheckCircle } from '@trustyou/fortawesome/pro-solid-svg-icons';
import SubmitButton from '../../../Login/Buttons/SubmitButton';

const CXPSuccess = () => {
  const session = useSelector((state) => state.app.session);

  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Stack sx={{ flexDirection: 'row' }}>
        <Icon icon={faCheckCircle} sx={{ width: '30px', height: '30px' }} color='green' />
        <Typography variant='h6' color='text.primary' sx={{ marginLeft: 1 }}>
          <FormattedMessage id='cxp_success_title' defaultMessage='Your account is now ready' />
        </Typography>
      </Stack>
      <Typography variant='body1' color='text.secondary' sx={{ marginY: 1 }}>
        {/* <FormattedMessage
          id='cxp_success_description'
          defaultMessage='Start exploring new features on Customer Experience Platform (CXP)'
        /> */}
        <FormattedMessage id='cxp_success_description_vog' defaultMessage='Start exploring new features on VOG 2.0' />
      </Typography>
      <SubmitButton
        sx={{ minWidth: '150px', marginTop: 5 }}
        onClick={() => window.open(`https://cxp.trustyou.com/app/?invited_email=${session?.user?.email}`)}
      >
        <FormattedMessage id='cxp_login_now' defaultMessage='Login now' />
      </SubmitButton>
    </Stack>
  );
};

export default CXPSuccess;
